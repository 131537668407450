import './App.css';
import React from 'react';

const birthday = new Date("February 21, 2022 03:24:00");

function App() {

  const age = {
    'years': Math.floor(getAge()/1000/60/60/24/356),
    'months': Math.floor(getAge()/1000/60/60/24/30,417),
    'days': Math.floor(getAge()/1000/60/60/24),
    'weeks': Math.floor(getAge()/1000/60/60/24/7)
  };

  function getAge() {
    let dateNow = new Date();
  
    return dateNow - birthday;
  }

  return (
    <div className='container' style={{ backgroundImage: 'url(sky.jpg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', width: '100%', height: '100vh' }}>
      <h1>{age.years} <span>Jahre</span> : {age.months} <span>Monate</span></h1>
      <h3>{age.days} <span>Tage</span></h3>
      <h2>{age.weeks} <span>Wochen</span></h2>
    </div>
  );
}

export default App;
